var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":/detil-product/ +
    _vm.produk.id_produk +
    /jenis-produk/ +
    _vm.produk.jenis_produk +
    /merk-produk/ +
    _vm.produk.merk_produk +
    /nama-produk/ +
    _vm.produk.nama_produk}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-4"},[_c('img',{staticClass:"img-loader lazy-img-loader loaded",attrs:{"src":_vm.produk.url_gambar_produk,"loading":"lazy","alt":_vm.produk.nama_produk}})]),_c('div',{staticClass:"col col-md-8 align-self-center"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"content-title"},[_vm._v(" "+_vm._s(_vm.produk.nama_produk))])]),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.produk.merk_produk))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }