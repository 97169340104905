<template>
  <a
    :href="
      /detil-product/ +
      produk.id_produk +
      /jenis-produk/ +
      produk.jenis_produk +
      /merk-produk/ +
      produk.merk_produk +
      /nama-produk/ +
      produk.nama_produk
    "
  >
    <div class="row">
      <div class="col col-md-4">
        <img
          :src="produk.url_gambar_produk"
          class="img-loader lazy-img-loader loaded"
          loading="lazy"
          :alt="produk.nama_produk"
        />
      </div>
      <div class="col col-md-8 align-self-center">
        <div class="title">
          <span class="content-title"> {{ produk.nama_produk }}</span>
        </div>
        <span class="subtitle">{{ produk.merk_produk }}</span>
        <!-- <div v-for="search in produk.detil_produk" :key="search.iddetilproduk">
          <span>{{ search.tipe_produk }}</span>
        </div> -->
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: ["produk"],
};
</script>

<style></style>
