<template>
  <!--====================  footer area ====================-->
  <div class="footer-area footer-color">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-content-wrapper">
            <div class="row">
              <div class="col-xl-5 col-lg-3 col-md-12">
                <FooterAbout :logo="data.footerLogo" :desc="data.footerDesc" />

                <ContactWidget :contactWidgetData="data.footerContactWidget" />
              </div>
              <div class="col-xl-2 col-lg-3 col-md-4">
                <FooterLink
                  :title="data.footerLinkTitleOne"
                  :nav="data.navListOne"
                />
              </div>
              <div class="col-xl-2 col-lg-3 col-md-4">
                <FooterLink
                  :title="data.footerLinkTitleTwo"
                  :nav="data.navListTwo"
                />
              </div>
              <!-- MEDSOS DAN MARKETPLACE -->
              <div class="col-xl-3 col-lg-3 col-md-4">
                <div class="footer-widget mb-4">
                  <h4 class="footer-widget__medsos">Media Sosial</h4>
                  <!-- MEDSOS -->
                  <div class="row">
                    <div class="col">
                      <div class="footer-medsos">
                        <a
                          href="https://www.facebook.com/putraramayana.co.id"
                          target="_blank"
                        >
                          <img
                            :src="data.logo_facebook"
                            class="img-fluid"
                            alt="logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="col">
                      <div class="footer-medsos">
                        <a
                          href="https://www.instagram.com/cv_putraramayana/"
                          target="_blank"
                        >
                          <img
                            :src="data.logo_ig"
                            class="img-fluid"
                            alt="logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="col">
                      <div class="footer-medsos">
                        <a
                          href="https://api.whatsapp.com/send/?phone=6285853527441&text&app_absent=0"
                          target="_blank"
                        >
                          <img
                            :src="data.logo_wa"
                            class="img-fluid"
                            alt="logo"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ECOMMERCE -->
                <div class="footer-widget mb-4">
                  <h4 class="footer-widget__medsos">Marketplace</h4>
                  <div class="row">
                    <div class="col">
                      <div class="footer-medsos">
                        <a
                          href="hhttps://www.tokopedia.com/putra-ramayana"
                          target="_blank"
                        >
                          <img
                            :src="data.logo_toped"
                            class="img-fluid"
                            alt="logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="col">
                      <div class="footer-medsos">
                        <a
                          href="https://shopee.co.id/cv_putraramayana18"
                          target="_blank"
                        >
                          <img
                            :src="data.logo_shopee"
                            class="img-fluid"
                            alt="logo"
                          />
                        </a>
                      </div>
                    </div>

                    <div class="col">
                      <div class="footer-medsos">
                        <a
                          href="https://www.bukalapak.com/u/cv_putraramayana"
                          target="_blank"
                        >
                          <img
                            :src="data.logo_bukalapak"
                            class="img-fluid"
                            alt="logo"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="footer-widget">
                  <h4 class="footer-widget__medsos">Lokasi Kita</h4>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15819.112752310504!2d110.8116531!3d-7.5991024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5223a52eb9fa3d6!2sPutra%20Ramayana.%20CV!5e0!3m2!1sen!2sid!4v1640079815128!5m2!1sen!2sid"
                    style="width: 100%; height: 100%; border: 1px"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright-wrapper">
      <div class="footer-copyright text-center">
        <p>
          &copy; {{ new Date().getFullYear() }}
          <a><b>CV. Putra Ramayana</b></a>
        </p>
      </div>
    </div>
  </div>
  <!--====================  End of footer area  ====================-->
</template>

<script src="https://unpkg.com/vue/dist/vue.min.js"></script>``
<script>
import FooterAbout from "@/components/FooterAbout.vue";
import FooterLink from "@/components/FooterLink.vue";
import ContactWidget from "@/components/ContactWidget.vue";

import data from "../data/footer.json";
export default {
  components: {
    FooterAbout,
    FooterLink,
    ContactWidget,
  },
  data() {
    return {
      data,
    };
  },
};
</script>

<style lang="scss"></style>
