<template>
  <!--====================  header area ====================-->
  <div class="header-area">
    <div class="header-area__desktop header-area__desktop--default">
      <!--=======  header info area  =======-->
      <div class="header-info-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="header-info-wrapper align-items-center">
                <!-- logo -->
                <div class="logo">
                  <router-link to="/">
                    <img
                      src="../assets/img/logo/logo-light.png"
                      class="img-fluid"
                      alt="Brand Logo"
                    />
                  </router-link>
                </div>

                <!-- mobile menu -->
                <div
                  class="mobile-navigation-icon"
                  id="mobile-menu-trigger"
                  @click="mobiletoggleClass('addClass', 'active')"
                >
                  <i></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--=======  End of header info area =======-->

      <!--=======  header navigation area  =======-->
      <fixed-header>
        <div class="header-navigation-area default-bg">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <!-- header navigation -->
                <div
                  class="
                    header-navigation header-navigation--header-default
                    position-relative
                  "
                >
                  <!-- logo -->
                  <div class="logo">
                    <router-link to="/">
                      <img
                        :src="data.footerLogo"
                        class="img-fluid"
                        alt="logo"
                      />
                    </router-link>
                  </div>
                  <div class="header-navigation__nav position-static">
                    <nav>
                      <ul>
                        <li>
                          <router-link to="/">HOME</router-link>
                        </li>
                        <!-- <li
                          class="has-children has-children--multilevel-submenu"
                        > -->
                        <li
                          class="header-navigation__nav position-static"
                        >
                          <router-link :to="/product-category/ + 'Ban Motor'"
                            >Produk</router-link
                          >
                          <!-- <ul class="submenu">
                            <li>
                              <a>Sparepart Roda 2</a>
                              <ul class="submenu">
                                <li
                                  v-for="listsparepartroda2 in listsparepartroda2"
                                  :key="listsparepartroda2.id_kategori"
                                >
                                  <router-link
                                    :to="
                                      /product-category/ +
                                      listsparepartroda2.kategori_produk +
                                      ' ' +
                                      listsparepartroda2.tipe_produk
                                    "
                                    ><a
                                      :href="
                                        /product-category/ +
                                        listsparepartroda2.kategori_produk +
                                        ' ' +
                                        listsparepartroda2.tipe_produk
                                      "
                                      >{{ listsparepartroda2.kategori_produk }}
                                      {{ listsparepartroda2.tipe_produk }}</a
                                    ></router-link
                                  >
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a>Sparepart Roda 4</a>
                              <ul class="submenu">
                                <li
                                  v-for="listsparepartroda4 in listsparepartroda4"
                                  :key="listsparepartroda4.id_kategori"
                                >
                                  <router-link
                                    :to="
                                      /product-category/ +
                                      listsparepartroda4.kategori_produk +
                                      ' ' +
                                      listsparepartroda4.tipe_produk
                                    "
                                    ><a
                                      :href="
                                        /product-category/ +
                                        listsparepartroda4.kategori_produk +
                                        ' ' +
                                        listsparepartroda4.tipe_produk
                                      "
                                      >{{ listsparepartroda4.kategori_produk }}
                                      {{ listsparepartroda4.tipe_produk }}</a
                                    ></router-link
                                  >
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a>Power Tools</a>
                              <ul class="submenu">
                                <li
                                  v-for="listpowertool in listpowertool"
                                  :key="listpowertool.id_kategori"
                                >
                                  <router-link
                                    :to="
                                      /product-category/ +
                                      listpowertool.kategori_produk +
                                      ' ' +
                                      listpowertool.tipe_produk
                                    "
                                    ><a
                                      :href="
                                        /product-category/ +
                                        listpowertool.kategori_produk +
                                        ' ' +
                                        listpowertool.tipe_produk
                                      "
                                      >{{ listpowertool.kategori_produk }}
                                      {{ listpowertool.tipe_produk }}
                                    </a></router-link
                                  >
                                </li>
                              </ul>
                            </li>
                          </ul> -->
                        </li>

                        <!-- <li
                          class="has-children has-children--multilevel-submenu"
                        >
                          <router-link :to="/product-category/ + 'Ban Motor'"
                            >Produk</router-link
                          >
                          <ul class="submenu">
                            <li
                              v-for="listkategori in listkategoris"
                              :key="listkategori.id_kategori"
                            >
                              <router-link
                                :to="
                                  /product-category/ +
                                  listkategori.kategori_produk
                                "
                                ><a
                                  :href="
                                    /product-category/ +
                                    listkategori.kategori_produk
                                  "
                                  >{{ listkategori.kategori_produk }}</a
                                ></router-link
                              >
                            </li>
                          </ul>
                        </li> -->
                        <li>
                          <router-link to="/contact">CONTACT</router-link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="col-12 col-md-12 col-lg-5 align-self-center header-search-align">
                    <div class="input-group">
                      <input
                        type="text"
                        v-model="search"
                        class="form-control"
                        placeholder="Cari barang disini ..."
                      />
                      <div class="input-group-prepend">
                        <a :href="/search/ + this.search">
                          <button class="btn btn-primary">
                            <i class="fa fa-search"></i></button
                        ></a>
                      </div>
                    </div>

                    <div
                      class="thumbnail-card"
                      v-if="showsearch == false && this.search.length > 0"
                    >
                      <div
                        class="card list-thumbnail mb-2"
                        v-for="produk in filteredProduks.slice(0, 3)"
                        :key="produk.id_produk"
                      >
                        <SearchList :produk="produk" />
                      </div>
                      <div class="row more-search">
                        <a :href="/search/ + this.search"
                          >Lihat lebih banyak
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fixed-header>
      <!--=======  End of header navigation area =======-->
    </div>
  </div>
  <!--====================  End of header area  ====================-->
</template>

<script>
import axios from "axios";
import FixedHeader from "vue-fixed-header";
import data from "../data/footer.json";
import SearchList from "@/components/SearchList";
export default {
  data() {
    return {
      data,
      listkategoris: [],
      listpowertool: [],
      listsparepartroda2: [],
      listsparepartroda4: [],
      showsearch: false,
      allproduks: [],
      search: "",
    };
  },
  components: {
    FixedHeader,
    SearchList,
  },
  computed: {
    filteredProduks: function () {
      var self = this;
      return this.allproduks.filter(function (allproduks) {
        return (
          allproduks.nama_produk
            .toLowerCase()
            .indexOf(self.search.toLowerCase()) >= 0 ||
          allproduks.merk_produk
            .toLowerCase()
            .indexOf(self.search.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    handleSearch() {
      this.$router.push("/search/" + this.search);
    },
    getAllData() {
      axios
        .get("https://admin.putraramayana.com/api/produks")
        .then((response) => {
          this.allproduks = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getListData() {
      axios
        .get("https://admin.putraramayana.com/api/list-kategori")
        .then((response) => {
          this.listkategoris = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getListPowerTool() {
      axios
        .get(
          "https://admin.putraramayana.com/api/list-kategori/" +
            "power tools"
        )
        .then((response) => {
          this.listpowertool = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getListSparepartRoda2() {
      axios
        .get("https://admin.putraramayana.com/api/list-kategori/" + "Motor")
        .then((response) => {
          this.listsparepartroda2 = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    getListSparepartRoda4() {
      axios
        .get("https://admin.putraramayana.com/api/list-kategori/" + "Mobil")
        .then((response) => {
          this.listsparepartroda4 = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    // offcanvas mobilemenu
    mobiletoggleClass(addRemoveClass, className) {
      const el = document.querySelector("#offcanvas-menu");
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
  mounted() {
    this.getAllData();
    this.getListData();
    this.getListSparepartRoda2();
    this.getListSparepartRoda4();
    this.getListPowerTool();
  },
};
</script>

<style lang="scss">
.vue-fixed-header--isFixed {
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 999;
  position: fixed;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running
    fadeInDown;
}
</style>