<template>
  <div class="footer-widget mb-0">
    <div class="footer-widget__content">
      <div class="row mb-2">
        <div class="col-1 footer-info">
          <i class="fa fa-map-marker"></i>
        </div>
        <div class="col-10 footer-center-row">
          <a target="_blank" href="https://goo.gl/maps/SbC5yf8xBD1QC3sv5">
            <p class="address">
              Jl. Vienna Utama 1-7, Solo Baru, Grogol, Sukoharjo, Jawa Tengah,
              57225
            </p></a
          >
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-1 footer-info">
          <i class="fa fa-phone"></i>
        </div>
        <div class="col-10 footer-center-row">
          <a class="footer-phone"><p class="address">(0271) 625-252</p> </a>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-1 footer-info">
          <i class="fa fa-whatsapp"></i>
        </div>
        <div class="col-10 footer-center-row">
          <a href="https://wa.me/6285853527441" target="_blank"
            ><p class="address">0858-5352-7441</p>
          </a>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-1 footer-info">
          <i class="fa fa-clock-o"></i>
        </div>
        <div class="col-10 footer-center-row">
          <p class="address">Senin - Sabtu : 08.00 - 17.00</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["contactWidgetData"],
};
</script>
