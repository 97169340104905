<template>
  <div class="footer-intro-wrapper mb-3">
    <div class="footer-logo">
      <router-link to="/">
        <img :src="logo" class="img-fluid" alt="logo" />
      </router-link>
    </div>
    <div class="footer-desc">
      {{ desc }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["logo", "desc"],
};
</script>
